<template>
	<!-- <v-col
		cols="12"
		md="8"
		sm="12"
		v-resize="onResize"
		class="ma-0 mt-10 mt-md-0 pa-0"
    
		:style="panelReproductorVideo()"
	> -->
	<v-col
		cols="12"
		md="8"
		sm="7"
		v-resize="onResize"
		class="ma-0 mt-10 mt-md-0 pa-0"
		:style="panelReproductorVideo()"
		style="position: relative"
	>
		<v-row class="">
			<v-col
				:style="
					this.$vuetify.breakpoint.name == 'sm'
						? 'position: fixed; top: 80px'
						: 'position: relative;'
				"
				class="ma-0 pa-0"
			>
				<div
					width="100%"
					:height="`${heightFrame}px`"
					id="videosesiones"
					ref="videosesiones"
					class="ma-0 pa-0 pt-md-0 pt-lg-0 pt-xl-0 pt-sm-2 pt-1"
					style="width: 100%"
				></div>
				<div style="position: absolute; width: 1%; bottom: 0; left: 0">
					<v-btn light icon @click="reload"><v-icon>mdi-refresh</v-icon></v-btn>
				</div>
				<div
					v-show="divEndSesion"
					style="position: absolute; width: 100%; top: 0"
					width="100%"
					:height="`${heightFrame}px`"
				>
					<v-row justify="center">
						<v-col cols="10">
							<v-row justify="center">
								<v-col cols="3">
									<v-progress-circular
										:rotate="180"
										:size="100"
										:width="15"
										:value="segundos"
										color="purple"
										indeterminate
									>
										{{ segundos }}
									</v-progress-circular>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-btn @click="reiniciarSiguienteSesion"> Detener </v-btn>
								</v-col>
								<v-col>
									<v-btn @click="siguienteSesion" light color="primary">
										Continuar con la siguiente sesión
									</v-btn>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-row>
		<v-row class="ma-0 pa-0 d-none d-xs-none d-md-flex" dark>
			<v-col>
				<h3 style="color: #000000">
					{{ videoSeleccionado.video_orden }}.
					{{ videoSeleccionado.video_title }}
				</h3>
				<h3 style="color: #000000">Sobre la Sesión:</h3>
				<p style="color: #000000">
					{{ videoSeleccionado.video_description }}
				</p>
				<h3 style="color: #000000">¿Qué aprenderás?</h3>
				<p style="color: #000000">
					{{ videoSeleccionado.video_queaprenderas }}
				</p>
				<p style="color: #000000">
					<strong>Conceptos Claves: </strong>
					<v-chip
						v-for="concepto in videoSeleccionado.video_conceptosclaves
							? videoSeleccionado.video_conceptosclaves.split(',')
							: []"
						:key="concepto.trim()"
					>
						{{ concepto.trim().replace("-", "") }}
					</v-chip>
				</p>
			</v-col>
		</v-row>
	</v-col>
</template>
<script>
// var setIntervalCurrentTime = null
/* eslint-disable no-console */
/* eslint-disable no-empty */
// eslint-disable-next-line no-unused-vars
// let videoplayer = {}
import { mapState, mapMutations } from "vuex";
// eslint-disable-next-line no-unused-vars
import apiVimeo from "../../services/apiVimeo";

export default {
	name: "videos_modulos",
	props: {
		sesion: {
			type: Object,
			default: () => {
				return {};
			},
		},
		isPremium: Number,
		heightFrame: Number,
		modulos: {
			type: Array,
			default: [],
		},
		track: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	async created() {},
	async mounted() {
		this.videoSeleccionado = this.sesion;
		let seconds = Math.floor(this.videoSeleccionado.video_seconds);
		this.videoplayer = new this.$Player("videosesiones", {
			id: this.videoSeleccionado.video_idvideovimeo,
			width: this.widthFrame,
			height: this.heightFrame,
		});

		await this.videoplayer.on("loaded", async () => {
			let tracks = this.videoplayer.getTextTracks();

			if (tracks && tracks.length > 0) {
				this.videoplayer.enableTextTrack("es-419");
			}
			let play = await this.videoplayer.play();
			console.log(play);
			this.videoplayer.setCurrentTime(seconds);
		});
		this.videoplayer.on("timeupdate", async (timeup) => {
			if (parseInt(timeup.seconds) % 4 == 0) {
				try {
					// console.group('setIntervalCurrentTime getCurrentTime')
					let time = 0;

					time = await this.videoplayer.getCurrentTime();

					let timestring = parseInt(time).toString();
					console.log(
						"Paso",
						time,
						parseInt(parseInt(time) / 60),
						parseInt(time) % 60,
						this.videoSeleccionado.video_seconds,
						timestring,
						timeup
					);
					let ruta = null;

					switch (this.$router.currentRoute.name) {
						case "miruta":
							ruta = "1";
							break;
						case "track":
							ruta = "0";
							break;
						default:
							ruta = "-1";
							break;
					}
					let params = {
						id_video: this.sesion.video_id,
						seconds_video: timestring,
						track_video: this.track.track_id,
						seconds_exac: parseInt(timeup.duration).toString(),
						type_track_ruta: ruta,
					};
					if (this.videoSeleccionado.video_seconds != timestring) {
						let response = await this.$http.post("/videos/playvideo", params);
						console.log("response", response.data, timeup.seconds);
						if (response.data) {
							this.sesion.video_seconds = timestring;
						}
						//   console.log(this.$setIntervalCurrentTime)
					}
				} catch (error) {}
			}
		});

		this.videoplayer.on("ended", () => {
			this.activeEndPlayVideo();
		});
	},
	methods: {
		...mapMutations(["SET_INFOTRACK"]),
		panelReproductorVideo() {
			// console.log(this.$vuetify.theme.defaults.dark.secondary)
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return `right:0; z-index:7; position:fixed;background:${this.$vuetify.theme.defaults.light.primary}; `;
				case "sm":
					return ` `;
				case "md":
					return "";
				case "lg":
					return "";
				case "xl":
					return "";
			}
		},

		async reload() {
			let seconds = Math.floor(parseInt(this.videoSeleccionado.video_seconds));
			this.reiniciarSiguienteSesion();
			await this.videoplayer.destroy();
			this.videoplayer = new this.$Player("videosesiones", {
				id: this.videoSeleccionado.video_idvideovimeo,
				width: this.widthFrame,
				height: this.heightFrame,
			});
			window.location.hash = this.videoSeleccionado.video_id;
			console.log(window.location.hash, this.videoSeleccionado.video_id);
			await this.videoplayer.on("loaded", async () => {
				let tracks = this.videoplayer.getTextTracks();

				if (tracks && tracks.length > 0) {
					this.videoplayer.enableTextTrack("es-419");
				}
				let play = await this.videoplayer.play();
				console.log(play);
				this.videoplayer.setCurrentTime(seconds);
			});
			this.videoplayer.on("timeupdate", async (timeup) => {
				if (parseInt(timeup.seconds) % 4 == 0) {
					try {
						// console.group('setIntervalCurrentTime getCurrentTime')
						let time = 0;

						time = await this.videoplayer.getCurrentTime();

						let timestring = parseInt(time).toString();
						console.log(
							"Paso",
							time,
							parseInt(parseInt(time) / 60),
							parseInt(time) % 60,
							this.videoSeleccionado.video_seconds,
							timestring,
							timeup
						);
						if (this.sesion.video_seconds != timestring) {
							let ruta = null;

							switch (this.$router.currentRoute.name) {
								case "miruta":
									ruta = "1";
									break;
								case "track":
									ruta = "0";
									break;
								default:
									ruta = "-1";
									break;
							}
							let params = {
								id_video: this.sesion.video_id,
								seconds_video: timestring,
								track_video: this.track.track_id,
								seconds_exac: parseInt(timeup.duration).toString(),
								type_track_ruta: ruta,
							};
							let response = await this.$http.post("/videos/playvideo", params);
							console.log("response", response.data, timeup.seconds);
							if (response.data) {
								let track_load = { ...this.track };
								track_load.modulos.forEach((modulo) => {
									modulo.sesiones.forEach((sesion) => {
										if (sesion.video_id == this.sesion.video_id) {
											sesion.video_seconds = parseInt(time);
											let sesion_load = { ...this.sesion };
											sesion_load.video_seconds = parseInt(time);
											this.$emit("update:sesion", sesion_load);
											this.$emit("update:track", track_load);
										}
									});
								});
							}
							//   console.log(this.$setIntervalCurrentTime)
						}
					} catch (error) {}
				}
			});

			this.videoplayer.on("ended", () => {
				this.activeEndPlayVideo();
			});
		},
		activeEndPlayVideo() {
			this.divEndSesion = true;

			this.$intervalActiveEndPlayVideo = setInterval(() => {
				console.log("Paso");
				if (this.segundos === 0) {
					this.siguienteSesion();
				}
				this.segundos -= 1;
			}, 1000);
		},
		siguienteSesion() {
			let positionOrder = parseInt(this.videoSeleccionado.video_orden);
			let siguienteModulo = false;
			let moduloSeleccionado = false;
			let hasSessionWelcome = false;
			let positionOrdenTemp = positionOrder;
			this.modulos.forEach((modulo) => {
				hasSessionWelcome = false;
				modulo.sesiones.forEach((sesion) => {
					if (sesion.video_orden == 0) {
						hasSessionWelcome = true;
						positionOrdenTemp++;
					}
					if (!hasSessionWelcome) {
						positionOrdenTemp = positionOrder;
					}
					// sesion.video_orden
					if (!moduloSeleccionado) {
						if (siguienteModulo) {
							this.$emit("update:sesion", modulo.sesiones[0]);
							moduloSeleccionado = true;
						} else {
							if (sesion.video_id == this.videoSeleccionado.video_id) {
								if (positionOrdenTemp + 1 <= modulo.sesiones.length) {
									this.$emit(
										"update:sesion",
										modulo.sesiones[positionOrdenTemp]
									);
								} else {
									console.log("siguiente modulo");
									siguienteModulo = true;
								}
							}
						}
					}
				});
			});
			this.reiniciarSiguienteSesion();
		},
		reiniciarSiguienteSesion() {
			console.log("reiniciarSiguienteSesion", this.$intervalActiveEndPlayVideo);
			this.$clearIntervalActiveEndPlayVideo(this);
			this.divEndSesion = false;
			this.segundos = 8;
		},
		onResize() {
			let div = document.querySelector("#videosesiones");

			console.log(
				"offsetHeight",
				window.screen.width,
				window.screen.height,
				this.$vuetify.breakpoint.name
			);

			if (
				this.$vuetify.breakpoint.name == "sm" &&
				window.screen.width / window.screen.height > 1
			) {
				this.widthFrame = (div.offsetWidth * 3) / 5;
				let heightFrame = (this.widthFrame * 16) / 9;
				this.$emit("update:heightFrame", heightFrame);

				try {
					div.querySelector("iframe").setAttribute("width", this.widthFrame);
				} catch (error) {}
				try {
					div.querySelector("iframe").setAttribute("height", heightFrame);
				} catch (error) {}
			} else {
				let heightFrame = (div.offsetWidth * 9) / 16;
				this.$emit("update:heightFrame", heightFrame);
				this.widthFrame = div.offsetWidth;
				try {
					div.querySelector("iframe").setAttribute("width", this.widthFrame);
				} catch (error) {}
				try {
					div.querySelector("iframe").setAttribute("height", this.heightFrame);
				} catch (error) {}
			}
		},
		async methodsSetIntervalCurrentTime() {
			try {
				this.$setIntervalCurrentTime = setInterval(() => {
					let getCurrentTime = async () => {
						try {
							// console.group('setIntervalCurrentTime getCurrentTime')
							let time = 0;

							time = await this.videoplayer.getCurrentTime();

							let timestring = parseInt(time).toString();
							console.log("Paso", time, timestring);
							if (this.videoSeleccionado.video_seconds != timestring) {
								let response = await this.$http.post("/videos/playvideo", {
									id_video: this.sesion.video_id,
									seconds_video: timestring,
									track_video: this.track.track_id,
								});
								console.log("response", response.data);
								if (response.data) {
									this.sesion.video_seconds = timestring;
								}
								//   console.log(this.$setIntervalCurrentTime)
							}
						} catch (error) {}
					};
					getCurrentTime();
				}, 5000);
			} catch (error) {}
		},
	},

	updated() {},
	watch: {
		async sesion(news, old) {
			// async sesion (news, old) {
			if (news.video_id != old.video_id) {
				this.videoSeleccionado = news;
				this.reload();
				// try {
				//   this.$clearIntervalActiveEndPlayVideo(this)
				//   console.group('sesion try watch')
				//   // console.log(news, old)
				//   this.videoSeleccionado = news

				//   let seconds = Math.floor(
				//     parseInt(this.videoSeleccionado.video_seconds)
				//   )
				//   console.log(seconds)
				//   console.log(this.videoSeleccionado)
				//   await this.videoplayer
				//     .loadVideo(this.videoSeleccionado.video_idvideovimeo)
				//     .then(async id => {

				//       this.videoplayer.setCurrentTime(seconds)
				//       let tracks = await this.videoplayer.getTextTracks()
				//       if (tracks && tracks.length > 0) {
				//         this.videoplayer.enableTextTrack('es-419')
				//       }
				//       this.videoplayer.play()
				//       console.log(id)
				//     })
				//   // this.videoplayer.on('play', () => {
				//   //   this.methodsSetIntervalCurrentTime()
				//   // })
				//   // this.videoplayer.on('ended', () => {
				//   //   this.activeEndPlayVideo()
				//   // })
				//   console.log(this.videoSeleccionado)
				//   console.groupEnd('sesion try watch')
				// } catch (error) {}
			}
		},
	},
	computed: {
		...mapState("carruseles", ["modulo", "videonumber"]),
		...mapState(["infoTrack", "membresia"]),

		btnAreaConocimiento() {
			return {
				"niu-background-gradient-emprendimientonegocio":
					this.modulo.areaconocimientoCode == "emprendimientonegocio",
				"niu-background-gradient-liderazgo":
					this.modulo.areaconocimientoCode == "liderazgo",
				"niu-background-gradient-desarrollofisico":
					this.modulo.areaconocimientoCode == "desarrollofisico",
				"niu-background-gradient-desarrollopersonal":
					this.modulo.areaconocimientoCode == "desarrollopersonal",
				"niu-background-gradient-conocimientotecnico":
					this.modulo.areaconocimientoCode == "conocimientotecnico",
				"niu-background-gradient-comunicacion":
					this.modulo.areaconocimientoCode == "comunicacion",
			};
		},
		imageHeight() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "800px";
				case "sm":
					return "1000px";
				case "md":
					return "500px";
				case "lg":
					return "600px";
				case "xl":
					return "600px";
			}
		},
		nameBreakpoint() {
			return this.$vuetify.breakpoint.name;
		},
		altoCarruselVideo() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "1300px";
				case "sm":
					return "1200px";
				case "md":
					// return 16 / 9
					return "800px";
				case "lg":
					// return 16 / 9
					return "800px";
				case "xl":
					// return 16 / 9
					return "800px";
			}
		},
		aspectoRatio() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return 4 / 3;
				case "sm":
					return 4 / 3;
				case "md":
					// return 16 / 9
					return 3 / 4;
				case "lg":
					// return 16 / 9
					return 4 / 3;
				case "xl":
					// return 16 / 9
					return 4 / 3;
			}
		},
		classBarraVerticalModulo() {
			return {
				"niu-background-gradient-emprendimientonegocio":
					this.modulo.areaconocimientoCode == "emprendimientonegocio",
				"niu-background-gradient-liderazgo":
					this.modulo.areaconocimientoCode == "liderazgo",
				"niu-background-gradient-desarrollofisico":
					this.modulo.areaconocimientoCode == "desarrollofisico",
				"niu-background-gradient-desarrollopersonal":
					this.modulo.areaconocimientoCode == "desarrollopersonal",
				"niu-background-gradient-conocimientotecnico":
					this.modulo.areaconocimientoCode == "conocimientotecnico",
				"niu-background-gradient-comunicacion":
					this.modulo.areaconocimientoCode == "comunicacion",
			};
		},
		moduloSeleccionado() {
			return this.$store.state.moduloSeleccionado;
		},
		vinetasAreaConocimiento() {
			return require("../../assets/vinetas/" +
				this.modulo.areaconocimientoCode +
				".png");
		},
		fullscreen() {
			this.screen = "";
			return this.screen;
		},
	},

	data() {
		return {
			setIntervalCurrentTime: null,
			interval: null,
			segundos: 8,
			divEndSesion: false,
			tab_modulo: null,
			showButtonOpenList: false,
			showOpenList: false,
			vimeo: [],
			showVideo: false,
			showCarousel: true,
			videoSeleccionado: {},
			screen: "",
			ordenVideoActivar: 1,
			videoplayer: "",
			test: [{ idvideo: 423440615 }, { idvideo: 418757076 }],
			idseleccionado: {},
			listVideo: 0,
			widthFrame: "600",
			model: null,
			loadCarrusel: null,
		};
	},
	beforeDestroy() {
		console.log("beforeDestroy");
		this.$clearIntervalCurrentTime(this);
		try {
			this.videoplayer.destroy();
		} catch (error) {}
		try {
			this.$clearIntervalActiveEndPlayVideo(this);
		} catch (error) {}
	},
	destroyed() {
		this.$clearIntervalCurrentTime(this);
	},
};
</script>
<style lang="scss" scoped>
$bg_page: #f5f5f5;
.niu-bg-page {
	background-color: $bg_page !important;
}
.niu-list-videos {
	background-color: $bg_page !important;
}
.niu-content-iconos {
	color: black;
	font-size: 35px;
	display: inline-block;
	width: 90%;
	position: relative;
	left: 55px;
	font-weight: bold;
	word-break: keep-all;
}
.niu-iconos {
	font-size: 1.2rem;
	font-weight: normal;
	color: #717171;
}
.niu-label {
	display: inline-block;
	border-radius: 10px;
	line-height: 5px;
	padding: 10px;
}
.niu-title-modulo {
	color: black;
	font-size: 35px;
	display: inline-block;
	width: 50%;
	position: relative;
	left: 55px;
	font-weight: bold;
}
.niu-color-dark {
	cursor: context-menu !important;
}
@media (min-width: 959px) {
	.v-application--is-ltr .v-window__next {
		right: -2%;
	}
	.v-application--is-ltr .v-window__prev {
		left: -2%;
	}
}
@media (min-width: 959px) {
	.v-application--is-ltr .v-window__next {
		right: 50% !important;
	}
}

@media (max-width: 1264px) and (min-width: 960px) {
	.v-window__prev {
		left: -20px !important;
	}
}
.niu-vineta {
	position: absolute;
	top: 0;
	float: left;
}
.niu-icon-play {
	position: relative;
	font-size: 230px !important;
	border-radius: 50%;
	border: 2px solid #fff;
	transform: translate(0%, 40%) !important;
}
.niu-icon-play:hover {
	border: 5px solid #590d53;
	color: #590d53;
}
.v-window__prev,
.v-window__next {
	top: 10px !important;
}
@media (max-width: 600px) {
	.niu-icon-play {
		font-size: 150px !important;
	}
	.v-window__prev,
	.v-window__next {
		top: 10px !important;
	}
	.v-window__prev {
		left: -25px !important;
	}
	.v-window__next {
		right: -25px !important;
	}
}

@media (min-width: 600px) and (max-width: 960px) {
	.niu-icon-play {
		font-size: 250px !important;
	}
}

@media (min-width: 960px) and (max-width: 1264px) {
	.niu-icon-play {
		font-size: 190px !important;
		transform: translate(0%, 70%) !important;
	}
	.v-window__prev,
	.v-window__next {
		top: 145px !important;
	}
	.v-window__prev {
		left: -10px !important;
	}
}
@media (min-width: 960px) and (max-width: 980px) {
	.v-window__next {
		right: 456px !important;
	}
}
@media (min-width: 980px) and (max-width: 1000px) {
	.v-window__next {
		right: 456px !important;
	}
}

@media (min-width: 1000px) and (max-width: 1050px) {
	.v-window__next {
		right: 510px !important;
	}
}
@media (min-width: 1050px) and (max-width: 1150px) {
	.v-window__next {
		right: 518px !important;
	}
}
@media (min-width: 1150px) and (max-width: 1200px) {
	.v-window__next {
		right: 552px !important;
	}
}
@media (min-width: 1200px) and (max-width: 1264px) {
	.v-window__next {
		right: 585px !important;
	}
}

@media (min-width: 1264px) and (max-width: 1904px) {
	.niu-icon-play {
		font-size: 220px !important;
		transform: translate(0%, 20%) !important;
	}
	.v-window__prev,
	.v-window__next {
		top: 145px !important;
	}
	.v-window__prev {
		left: -10px !important;
	}
}
@media (min-width: 1264px) and (max-width: 1400px) {
	.v-window__next {
		right: 615px !important;
	}
}
@media (min-width: 1400px) and (max-width: 1600px) {
	.v-window__next {
		right: 745px !important;
	}
}
@media (min-width: 1600px) and (max-width: 1904px) {
	.v-window__next {
		right: 650px !important;
	}
}
@media (min-width: 1904px) {
	.v-window__prev,
	.v-window__next {
		top: 145px !important;
	}
	.v-window__next {
		right: 590px !important;
	}
	.v-window__prev {
		left: -10px !important;
	}
}
.niu-break-word {
	word-break: break-word !important;
}
.niu-font-size-34 {
	font-size: 34px !important;
}
.niu-barra-vertical-video {
	position: absolute;
	height: 100%;
	width: 6px;
	top: 0px;
	background-color: transparent;
	right: -50px;
	border-radius: 20px;
}
.niu-barra-vertical-video-2 {
	position: absolute;
	height: 92%;
	width: 6px;
	top: 8%;
	background-color: transparent;
	left: 0;
	border-radius: 20px;
}
.niu-titulo-video-modulo {
	font-size: 40px !important;
}
.niu-descripcion-video-modulo {
	font-size: 20px !important;
}
.niu-z-index-3 {
	z-index: 3;
}
.niu-z-index-2 {
	z-index: 2;
}
.niu-z-index-1 {
	z-index: 1;
}
.niu-col-vineta {
	height: 90px;
}
.niu-top-menos-30 {
	top: -30px;
}
.niu-vineta {
	opacity: 0.3;
	z-index: 1;
	top: 15px;
}

.niu-vineta.active {
	z-index: 2;
	opacity: 1;
	top: 0px;
}
.niu-vineta.niu-sesion1 {
	left: 0px;
}
.niu-vineta.niu-sesion2 {
	left: 30px;
}
.niu-vineta.niu-sesion3 {
	left: 60px;
}
#videosesiones > iframe {
	position: relative;
}
</style>
