<template>
	<v-col
		cols="12"
		sm="5"
		md="4"
		class="elevation-0 niu-details-video"
		:style="heightFrameCalc()"
	>
		<v-card
			v-if="modulos.length > 0"
			flat
			light
			class="elevation-0 niu-transparent"
		>
			<v-card-text>
				<h2 style="color: #fff">{{ tracktitle }}</h2>
			</v-card-text>
			<v-card-text class="" v-for="(modulo, n) in modulos" :key="n">
				<div class="" style="width: 100%; position: relative">
					<div
						class="modulo-list-label"
						:class="cssModuloActive(modulo, 'label')"
					>
						{{ modulo.modulo_orden }}.-
						{{ modulo.modulo_title }}
					</div>
				</div>
				<v-row
					v-for="(video, index) in modulo.sesiones"
					:key="'video_' + video.video_id + index"
				>
					<v-col cols="12">
						<a
							style="position: relative; height: 100%; display: inline-block"
							:href="`#${video.video_id}`"
							@click="changeVideoSeleccionado(video)"
							class="ma-0"
						>
							<div
								style="font-weight: bold; display: inline-block; height: 100%"
								:style="
									video.video_id == sesionSeleccionada.video_id
										? `color:#FFF;`
										: `color:rgb(255 255 255 / 42%);`
								"
							>
								<v-icon dark> mdi-play-circle </v-icon>
								{{ video.video_orden }}.-
								{{ video.video_title }}

								<v-tooltip
									top
									v-for="(contenido, index2) in video.contenido_complementario"
									:key="'contenido_' + index2"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											light
											icon
											target="_blank"
											:href="contenido.contenido"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon color="#FFF">
												{{ contenido.icon }}
											</v-icon>
										</v-btn>
									</template>
									<span>{{ contenido.name }}</span>
								</v-tooltip>
							</div>
						</a>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-col>
</template>
<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
// eslint-disable-next-line no-unused-vars
let videoplayer = {};
import { mapState, mapMutations } from "vuex";

export default {
	props: {
		modulos: {
			type: Array,
			default: [],
		},
		isPremium: Number,
		tracktitle: String,
		sesionSeleccionada: {
			type: Object,
			default: () => {
				return {};
			},
		},
		heightFrame: Number,
		heightTrack: Number,
	},
	async created() {},
	async mounted() {
		this.valheightTrack = this.heightTrack;
		// for (let ii = 0; ii < this.modulos.length; ii++) {
		// 	const element = this.modulos[ii].sesiones;
		// 	for (let index = 0; index < element.length; index++) {
		// 		let uno = (await apiVimeo(element[index].video_idvideovimeo)).data;
		// 		uno = uno
		// 			.find((ele) => ele.active == true)
		// 			.sizes.find((ele) => ele.width == 1280).link;
		// 		this.$set(this.modulos[ii].sesiones[index], "vimeo", uno);
		// 	}
		// }
		// console.group('mounted detailsVideo')
		// console.log(this.heightFrame)
		// console.log(this.sesionSeleccionada)
		// console.log(this.modulos)
		// console.groupEnd('mounted detailsVideo')

		// videoplayer.on('ended', () => {
		//   this.activarFind()
		// })
		// this.activarFind()
	},
	methods: {
		...mapMutations(["SET_INFOTRACK"]),
		activarFind() {
			// this.divEndSesion = true
			// let pos = this.videoSeleccionado.videoOrden + 1
			// if (pos < this.videos.length) {
			//   this.interval = setInterval(() => {
			//     console.log('Paso')
			//     if (this.segundos === 0) {
			//       this.siguienteSesion()
			//     }
			//     this.segundos -= 1
			//   }, 1000)
			// }
		},
		activaModelPremium() {
			this.$emit("OpenDialogModuloPremium");
		},
		async imgVimeo(video) {
			console.log(video);
			return video.vimeo;
		},
		closeOpenList() {
			this.showOpenList = false;
		},
		showVideoFunction(idvideo, code) {
			if (idvideo && code) {
				this.$router.push({
					name: "sesion",
					params: {
						id: idvideo,
						code: code,
					},
				});
			}
		},
		// eslint-disable-next-line no-unused-vars
		cssModuloActive(modulo, numberOrLabel = "label") {
			let selected = false;
			modulo.sesiones.forEach((element) => {
				// console.log(element, this.sesionSeleccionada)
				if (element.video_id == this.sesionSeleccionada.video_id) {
					selected = true;
				}
			});
			// console.log(this.sesionSeleccionada, modulo, numberOrLabel, selected)
			return {
				active: selected,
			};
		},
		async cambiar(id) {
			this.idseleccionado = id.idvideo;
		},
		async changeVideoSeleccionado(video) {
			this.$emit("update:sesionSeleccionada", video);
			// console.log(video)
			window.location.hash = video.video_id;
			// try {
			//   if (video.length > 0) {
			//     this.videoSeleccionado = video[0]
			//     console.log(this.videoSeleccionado)
			//     await videoplayer
			//       .loadVideo(this.videoSeleccionado.videoIdVideoVimeo)
			//       .then(() => {
			//         videoplayer.play()
			//         videoplayer.enableTextTrack('es-419')
			//         // videoplayer.on('ended', () => {
			//         //   this.activarFind()
			//         // })
			//       })
			//   } else {
			//     this.videoSeleccionado = video
			//     console.log(this.videoSeleccionado)
			//     await videoplayer
			//       .loadVideo(this.videoSeleccionado.videoIdVideoVimeo)
			//       .then(() => {
			//         videoplayer.play()
			//         // videoplayer.on('ended', () => {
			//         //   this.activarFind()
			//         // })
			//       })
			//   }
			//   console.log(this.videoSeleccionado)
			// } catch (error) {
			//   clearInterval(this.interval)
			// }
		},
		siguienteSesion() {
			let pos = this.videoSeleccionado.videoOrden + 1;
			this.changeVideoSeleccionado(
				this.videos.filter((ele) => {
					return ele.videoOrden == pos;
				})
			);
			this.reiniciarSiguienteSesion();
		},
		reiniciarSiguienteSesion() {
			clearInterval(this.interval);
			this.divEndSesion = false;
			this.segundos = 3;
		},
		methodsSetIntervalCurrentTime() {
			// this.setIntervalCurrentTime = setInterval(async () => {
			//   console.log('Paso')
			//   let time = 0
			//   time = await videoplayer.getCurrentTime()
			//   console.log(time)
			// }, 20000)
		},
		heightFrameCalc() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return `top:${this.heightFrame}px; height:calc(100% - ${
						this.heightFrame - 200
					}px);z-index:6;`;
				case "sm":
					return ` `;
				case "md":
					return "";
				case "lg":
					return "";
				case "xl":
					return "";
			}
		},
	},

	updated() {},
	computed: {
		...mapState("carruseles", ["modulo", "videonumber"]),
		...mapState(["infoTrack", "membresia"]),
		btnAreaConocimiento() {
			return {
				"niu-background-gradient-emprendimientonegocio":
					this.modulo.areaconocimientoCode == "emprendimientonegocio",
				"niu-background-gradient-liderazgo":
					this.modulo.areaconocimientoCode == "liderazgo",
				"niu-background-gradient-desarrollofisico":
					this.modulo.areaconocimientoCode == "desarrollofisico",
				"niu-background-gradient-desarrollopersonal":
					this.modulo.areaconocimientoCode == "desarrollopersonal",
				"niu-background-gradient-conocimientotecnico":
					this.modulo.areaconocimientoCode == "conocimientotecnico",
				"niu-background-gradient-comunicacion":
					this.modulo.areaconocimientoCode == "comunicacion",
			};
		},
		imageHeight() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "800px";
				case "sm":
					return "1000px";
				case "md":
					return "500px";
				case "lg":
					return "600px";
				case "xl":
					return "600px";
			}
		},

		nameBreakpoint() {
			return this.$vuetify.breakpoint.name;
		},
		altoCarruselVideo() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "1300px";
				case "sm":
					return "1200px";
				case "md":
					// return 16 / 9
					return "800px";
				case "lg":
					// return 16 / 9
					return "800px";
				case "xl":
					// return 16 / 9
					return "800px";
			}
		},
		aspectoRatio() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return 4 / 3;
				case "sm":
					return 4 / 3;
				case "md":
					// return 16 / 9
					return 3 / 4;
				case "lg":
					// return 16 / 9
					return 4 / 3;
				case "xl":
					// return 16 / 9
					return 4 / 3;
			}
		},
		classBarraVerticalModulo() {
			return {
				"niu-background-gradient-emprendimientonegocio":
					this.modulo.areaconocimientoCode == "emprendimientonegocio",
				"niu-background-gradient-liderazgo":
					this.modulo.areaconocimientoCode == "liderazgo",
				"niu-background-gradient-desarrollofisico":
					this.modulo.areaconocimientoCode == "desarrollofisico",
				"niu-background-gradient-desarrollopersonal":
					this.modulo.areaconocimientoCode == "desarrollopersonal",
				"niu-background-gradient-conocimientotecnico":
					this.modulo.areaconocimientoCode == "conocimientotecnico",
				"niu-background-gradient-comunicacion":
					this.modulo.areaconocimientoCode == "comunicacion",
			};
		},
		moduloSeleccionado() {
			return this.$store.state.moduloSeleccionado;
		},
		vinetasAreaConocimiento() {
			return require("../../assets/vinetas/" +
				this.modulo.areaconocimientoCode +
				".png");
		},
		fullscreen() {
			this.screen = "";
			return this.screen;
		},
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	data() {
		return {
			setIntervalCurrentTime: null,
			interval: null,
			segundos: 3,
			divEndSesion: false,
			tab_modulo: null,
			showButtonOpenList: false,
			showOpenList: false,
			vimeo: [],
			showVideo: false,
			showCarousel: true,
			videoSeleccionado: {},
			screen: "",
			ordenVideoActivar: 1,
			videoplayer: "",
			test: [{ idvideo: 423440615 }, { idvideo: 418757076 }],
			idseleccionado: {},
			listVideo: 0,
			widthFrame: "600",
			model: null,
			loadCarrusel: null,
			valheightTrack: 30,
		};
	},
	// beforeDestroy () {

	//   try {
	//     this.videoplayer.destroy()
	//     // clearInterval(this.popfleex)
	//   } catch (error) {
	//   }
	// }
};
</script>
<style lang="scss" scoped>
.niu-details-video {
	height: 90%;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #4d1552;
	position: fixed;
	right: 0;
	z-index: 6;
}

@media only screen and (max-width: 960px) {
	.niu-details-video {
		overflow-y: auto;
		overflow-x: hidden;
		background-color: #4d1552;
		position: relative;
		// z-index: 6;
	}
}

.modulo-list-number-interno {
	display: none;
}
.modulo-list-number {
	background-color: #fff;
	color: #000;
	display: inline-block;

	font-weight: bold;
	position: relative;
	right: 0px;
	width: 40px;

	border-radius: 50%;
	padding: 10px 0 5px;
	height: 40px;
	text-align: center;
}
.modulo-list-label {
	background-color: #fff;
	color: #000;
	display: inline-block;
	font-weight: bold;
	position: relative;
	right: 0px;
	width: 100%;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	padding: 15px 0 20px 20px;
}
.modulo-list-number.active,
.modulo-list-label.active {
	background-color: #ff7d12;
	color: #fff;
}
.modulo-list-label,
.modulo-list-number,
.modulo-list-number-interno {
	font-size: 13px;
	line-height: 12px;
}
@media only screen and (min-width: 960px) and (max-width: 1500px) {
	.modulo-list-number {
		display: none;
	}
	.modulo-list-number-interno {
		display: inline-block;
	}
	.modulo-list-label {
		background-color: #fff;
		color: #000;
		display: inline-block;
		font-weight: bold;
		position: relative;
		right: 0px;
		width: 100%;
		border-top-left-radius: 25px;
		border-bottom-left-radius: 25px;
		padding: 10px 0 20px 20px;
		// margin: 2px 0 5px 5px;
		height: 50px;
	}
}
.niu-transparent,
.theme--dark.v-sheet.niu-transparent {
	background: transparent;
}
$bg_page: #f5f5f5;
.niu-bg-page {
	background-color: $bg_page !important;
}
.niu-list-videos {
	background-color: $bg_page !important;
}
.niu-content-iconos {
	color: black;
	font-size: 35px;
	display: inline-block;
	width: 90%;
	position: relative;
	left: 55px;
	font-weight: bold;
	word-break: keep-all;
}
.niu-iconos {
	font-size: 1.2rem;
	font-weight: normal;
	color: #717171;
}
.niu-label {
	display: inline-block;
	border-radius: 10px;
	line-height: 5px;
	padding: 10px;
}
.niu-title-modulo {
	color: black;
	font-size: 35px;
	display: inline-block;
	width: 50%;
	position: relative;
	left: 55px;
	font-weight: bold;
}
.niu-color-dark {
	cursor: context-menu !important;
}
@media (min-width: 959px) {
	.v-application--is-ltr .v-window__next {
		right: -2%;
	}
	.v-application--is-ltr .v-window__prev {
		left: -2%;
	}
}
@media (min-width: 959px) {
	.v-application--is-ltr .v-window__next {
		right: 50% !important;
	}
}

@media (max-width: 1264px) and (min-width: 960px) {
	.v-window__prev {
		left: -20px !important;
	}
}
.niu-vineta {
	position: absolute;
	top: 0;
	float: left;
}
.niu-icon-play {
	position: relative;
	font-size: 230px !important;
	border-radius: 50%;
	border: 2px solid #fff;
	transform: translate(0%, 40%) !important;
}
.niu-icon-play:hover {
	border: 5px solid #590d53;
	color: #590d53;
}
.v-window__prev,
.v-window__next {
	top: 10px !important;
}
@media (max-width: 600px) {
	.niu-icon-play {
		font-size: 150px !important;
	}
	.v-window__prev,
	.v-window__next {
		top: 10px !important;
	}
	.v-window__prev {
		left: -25px !important;
	}
	.v-window__next {
		right: -25px !important;
	}
}

@media (min-width: 600px) and (max-width: 960px) {
	.niu-icon-play {
		font-size: 250px !important;
	}
}

@media (min-width: 960px) and (max-width: 1264px) {
	.niu-icon-play {
		font-size: 190px !important;
		transform: translate(0%, 70%) !important;
	}
	.v-window__prev,
	.v-window__next {
		top: 145px !important;
	}
	.v-window__prev {
		left: -10px !important;
	}
}
@media (min-width: 960px) and (max-width: 980px) {
	.v-window__next {
		right: 456px !important;
	}
}
@media (min-width: 980px) and (max-width: 1000px) {
	.v-window__next {
		right: 456px !important;
	}
}

@media (min-width: 1000px) and (max-width: 1050px) {
	.v-window__next {
		right: 510px !important;
	}
}
@media (min-width: 1050px) and (max-width: 1150px) {
	.v-window__next {
		right: 518px !important;
	}
}
@media (min-width: 1150px) and (max-width: 1200px) {
	.v-window__next {
		right: 552px !important;
	}
}
@media (min-width: 1200px) and (max-width: 1264px) {
	.v-window__next {
		right: 585px !important;
	}
}

@media (min-width: 1264px) and (max-width: 1904px) {
	.niu-icon-play {
		font-size: 220px !important;
		transform: translate(0%, 20%) !important;
	}
	.v-window__prev,
	.v-window__next {
		top: 145px !important;
	}
	.v-window__prev {
		left: -10px !important;
	}
}
@media (min-width: 1264px) and (max-width: 1400px) {
	.v-window__next {
		right: 615px !important;
	}
}
@media (min-width: 1400px) and (max-width: 1600px) {
	.v-window__next {
		right: 745px !important;
	}
}
@media (min-width: 1600px) and (max-width: 1904px) {
	.v-window__next {
		right: 650px !important;
	}
}
@media (min-width: 1904px) {
	.v-window__prev,
	.v-window__next {
		top: 145px !important;
	}
	.v-window__next {
		right: 590px !important;
	}
	.v-window__prev {
		left: -10px !important;
	}
}
.niu-break-word {
	word-break: break-word !important;
}
.niu-font-size-34 {
	font-size: 34px !important;
}
.niu-barra-vertical-video {
	position: absolute;
	height: 100%;
	width: 6px;
	top: 0px;
	background-color: transparent;
	right: -50px;
	border-radius: 20px;
}
.niu-barra-vertical-video-2 {
	position: absolute;
	height: 92%;
	width: 6px;
	top: 8%;
	background-color: transparent;
	left: 0;
	border-radius: 20px;
}
.niu-titulo-video-modulo {
	font-size: 40px !important;
}
.niu-descripcion-video-modulo {
	font-size: 20px !important;
}
.niu-z-index-3 {
	z-index: 3;
}
.niu-z-index-2 {
	z-index: 2;
}
.niu-z-index-1 {
	z-index: 1;
}
.niu-col-vineta {
	height: 90px;
}
.niu-top-menos-30 {
	top: -30px;
}
.niu-vineta {
	opacity: 0.3;
	z-index: 1;
	top: 15px;
}

.niu-vineta.active {
	z-index: 2;
	opacity: 1;
	top: 0px;
}
.niu-vineta.niu-sesion1 {
	left: 0px;
}
.niu-vineta.niu-sesion2 {
	left: 30px;
}
.niu-vineta.niu-sesion3 {
	left: 60px;
}
#videomodulosesiones > iframe {
	position: relative;
}
</style>
